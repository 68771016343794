import React from 'react';
import VideoCard from '../VideoCard/VideoCard';
import styles from './LandingPage.module.css';
import { useDocumentHead } from '../../hooks/useDocumentHead';

const videoData = [
    {
        id: 1,
        title: "Building an AI Image Generator",
        description: "Learn how to create an AI Image generator and deploy it using Cloudflare Pages",
        url: "https://youtu.be/QiEY1ztYKRE",
        author: "Saad Arif",
        authorUsername: "saadaryf",
    },
];

const LandingPage = () => {

    useDocumentHead(
        'DevsAura - Developer Insights & Tutorials',
        'Explore expert insights, coding tutorials, and real-world experiences from developers worldwide. Join DevsAura to stay ahead in the tech world!',
        'index, follow'
    );

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <header className={styles.header}>
                    <div className={styles.logoContainer}>
                        <img
                            src="/Logo.svg"
                            alt="DevsAura Logo"
                            className={styles.logoSvg}
                        />
                        <span className={styles.logoText}>DevsAura</span>
                    </div>
                </header>

                <section className={styles.hero}>
                    <h1 className={styles.title}>A Hub of Developers' Insights</h1>
                    <p className={styles.subtitle}>
                        by @saadaryf
                    </p>
                </section>

                <section className={styles.section}>
                    <div className={styles.videoGrid}>
                        {videoData.map(video => (
                            <VideoCard
                                key={video.id}
                                video={video}
                            />
                        ))}
                    </div>
                </section>

                <footer className={styles.footer}>
                    <p>© 2025 DevsAura. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default LandingPage;