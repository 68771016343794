import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage/LandingPage';
import VideoResources from './components/VideoResources/VideoResources';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/video/:id" element={<VideoResources />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;