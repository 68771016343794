import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import videoData from '../../VideoData/videoData';
import styles from './VideoResources.module.css';
import { useDocumentHead } from '../../hooks/useDocumentHead';

const VideoResources = () => {
    const { id } = useParams();
    const [videoResource, setVideoResource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('');
    const [promptText, setPromptText] = useState('');

    useDocumentHead(
        videoResource ? `${videoResource.title} - Tutorial` : 'Tutorial Resources',
        videoResource ? videoResource.description : 'Learn from detailed tutorial resources',
        'index, follow'
    );

    useEffect(() => {
        // Simulate API fetch with a small delay
        setLoading(true);
        const timer = setTimeout(() => {
            const foundVideo = videoData.find(video => video.id === parseInt(id));
            setVideoResource(foundVideo || null);

            if (foundVideo && foundVideo.content) {
                processContentDirect(foundVideo.content);
            }

            setLoading(false);
        }, 300);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [id]);

    // A new approach - directly create HTML elements instead of trying to parse markdown
    const processContentDirect = (markdown) => {
        if (!markdown) return;

        // Split content by the prompt section
        const parts = markdown.split("## Prompt");

        if (parts.length > 1) {
            // Create tutorial content HTML directly
            const tutorialHTML = createHTMLFromMarkdown(parts[0]);
            setContent(tutorialHTML);

            // Set prompt separately
            setPromptText(parts[1].trim());
        } else {
            // No prompt section found
            setContent(createHTMLFromMarkdown(markdown));
            setPromptText('');
        }
    };

    // Function to manually convert markdown to HTML
    const createHTMLFromMarkdown = (markdown) => {
        let html = '<div>';

        // Split markdown into lines for processing
        const lines = markdown.split('\n');
        let inOrderedList = false;
        let inUnorderedList = false;
        let codeBlockContent = '';
        let inCodeBlock = false;

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i].trim();

            // Skip empty lines
            if (!line && !inCodeBlock) continue;

            // Check for code blocks
            if (line.startsWith('```')) {
                if (!inCodeBlock) {
                    inCodeBlock = true;
                    codeBlockContent = '';
                } else {
                    // End of code block
                    inCodeBlock = false;
                    html += `
                    <div class="${styles.codeBlock}">
                        <div class="${styles.codeBlockHeader}">
                            <div class="${styles.codeBlockTitle}">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75 2.5H2.5C2.08579 2.5 1.75 2.83579 1.75 3.25V12.75C1.75 13.1642 2.08579 13.5 2.5 13.5H5.75M5.75 2.5H13.5C13.9142 2.5 14.25 2.83579 14.25 3.25V12.75C14.25 13.1642 13.9142 13.5 13.5 13.5H5.75M5.75 2.5V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Code
                            </div>
                            <div class="${styles.codeActions}">
                                <button class="${styles.codeActionButton}" onclick="copyCode(this)">
                                    <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.75 4.75H4.75C3.64543 4.75 2.75 5.64543 2.75 6.75V11.25C2.75 12.3546 3.64543 13.25 4.75 13.25H9.25C10.3546 13.25 11.25 12.3546 11.25 11.25V10.25M8.25 8.25L13.25 3.25M13.25 3.25H10.75M13.25 3.25V5.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <pre class="${styles.codeBlockContent}">${codeBlockContent}</pre>
                    </div>`;
                }
                continue;
            }

            // If in code block, just add the line to code content
            if (inCodeBlock) {
                codeBlockContent += line + '\n';
                continue;
            }

            // Headers
            if (line.startsWith('# ')) {
                html += `<h1>${line.substring(2)}</h1>`;
                continue;
            }
            if (line.startsWith('## ')) {
                html += `<h2>${line.substring(3)}</h2>`;
                continue;
            }
            if (line.startsWith('### ')) {
                html += `<h3>${line.substring(4)}</h3>`;
                continue;
            }

            // Ordered lists
            if (/^\d+\.\s+/.test(line)) {
                if (!inOrderedList) {
                    html += '<ol>';
                    inOrderedList = true;
                }

                // Get the list item content without the number
                const itemContent = line.replace(/^\d+\.\s+/, '');

                // Process bold and links in list items
                const processedItem = processInlineFormatting(itemContent);

                html += `<li>${processedItem}</li>`;
                continue;
            } else if (inOrderedList) {
                html += '</ol>';
                inOrderedList = false;
            }

            // Unordered lists
            if (line.startsWith('- ')) {
                if (!inUnorderedList) {
                    html += '<ul>';
                    inUnorderedList = true;
                }

                const itemContent = line.substring(2);
                const processedItem = processInlineFormatting(itemContent);

                html += `<li>${processedItem}</li>`;
                continue;
            } else if (inUnorderedList) {
                html += '</ul>';
                inUnorderedList = false;
            }

            // Regular paragraph
            if (line) {
                const processedLine = processInlineFormatting(line);
                html += `<p>${processedLine}</p>`;
            }
        }

        // Close any open lists
        if (inOrderedList) html += '</ol>';
        if (inUnorderedList) html += '</ul>';

        html += '</div>';
        return html;
    };

    // Process inline formatting (bold, italics, links)
    const processInlineFormatting = (text) => {
        // Bold
        let processed = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Italics
        processed = processed.replace(/\*(.*?)\*/g, '<em>$1</em>');

        // Links
        processed = processed.replace(/\[(.*?)\]\((.*?)\)/g,
            '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

        return processed;
    };

    // Copy code function for the in-page script
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            function copyCode(button) {
                const codeBlock = button.closest('.${styles.codeBlock}').querySelector('.${styles.codeBlockContent}');
                const textToCopy = codeBlock.textContent;
                
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        const originalText = button.innerHTML;
                        button.innerHTML = '<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 4.75L6.75 11.25L2.75 7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> Copied!';
                        
                        setTimeout(() => {
                            button.innerHTML = originalText;
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Failed to copy text: ', err);
                    });
            }
        `;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Link to="/" className={styles.backButton}>
                    <svg className={styles.buttonIcon} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12L6 8L10 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Back to Tutorials
                </Link>

                {!loading && videoResource && (
                    <a
                        href={videoResource.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.watchButton}
                    >
                        <svg className={styles.buttonIcon} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3L13 8L3 13V3Z" fill="currentColor" />
                        </svg>
                        Watch on YouTube
                    </a>
                )}
            </div>

            {loading ? (
                <div className={styles.loading}>
                    <div className={styles.spinner}></div>
                    <p>Loading tutorial resources...</p>
                </div>
            ) : videoResource ? (
                <div className={styles.content}>
                    <div className={styles.contentHeader}>
                        <h1 className={styles.title}>{videoResource.title}</h1>
                        <p className={styles.description}>{videoResource.description}</p>
                    </div>

                    <div className={styles.contentBody}>
                        <div
                            className={styles.markdownContent}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />

                        {promptText && (
                            <>
                                <h2>Prompt for AI Image Generator</h2>
                                <div className={styles.promptContainer}>
                                    <div className={styles.promptHeader}>
                                        <div className={styles.promptTitle}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.75 2.5H2.5C2.08579 2.5 1.75 2.83579 1.75 3.25V12.75C1.75 13.1642 2.08579 13.5 2.5 13.5H5.75M5.75 2.5H13.5C13.9142 2.5 14.25 2.83579 14.25 3.25V12.75C14.25 13.1642 13.9142 13.5 13.5 13.5H5.75M5.75 2.5V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            Prompt
                                        </div>
                                        <div className={styles.promptActions}>
                                            <button className={styles.promptActionButton} onClick={() => {
                                                navigator.clipboard.writeText(promptText);
                                                const button = document.querySelector(`.${styles.promptActionButton}`);
                                                const originalHTML = button.innerHTML;
                                                button.innerHTML = '<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 4.75L6.75 11.25L2.75 7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> Copied!';
                                                setTimeout(() => {
                                                    button.innerHTML = originalHTML;
                                                }, 2000);
                                            }}>
                                                <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.75 2.75H11.25C12.3546 2.75 13.25 3.64543 13.25 4.75V11.25C13.25 12.3546 12.3546 13.25 11.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.25 2.75V1.75H2.75V8.25H3.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                    <pre className={styles.promptContent}>{promptText}</pre>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.error}>
                    <h2>Resource Not Found</h2>
                    <p>The requested tutorial resource could not be found.</p>
                    <Link to="/" className={styles.button}>
                        Return to Home
                    </Link>
                </div>
            )}
        </div>
    );
};

export default VideoResources;