// videoData.js
const videoData = [
    {
        id: 1,
        title: "Building an AI Image Generator",
        description: "Learn how to create an AI Image generator and deploy it using Cloudflare Pages",
        url: "https://youtu.be/QiEY1ztYKRE",
        author: "Saad Arif",
        authorUsername: "saadaryf",
        content: `# Generate Your Own AI Image Generator Live
  
  No API Cost, No Hosting Cost, No Domain Name Buying
  
  If you think this is helpful, then share it with your friends and
  **subscribe to my YouTube channel** for the best simple AI tutorials in
  the future!
  [Subscribe Now](https://www.youtube.com/@saadaryf)
  
  ## Complete Guide
  
  ### Step 1: Create Your AI Image Generator
  
  1.  **Create an account** on [Claude.ai](https://claude.ai/) and put the
      prompt given below.
  
  2.  **Copy the given file** and save it as index.html.
  
  3.  **Create a folder** and save the index.html file inside it.
  
  ### Step 2: Deploy on Cloudflare Pages
  
  1.  **Create an account** on [Cloudflare](https://dash.cloudflare.com/).
  
  2.  **Go to Workers & Pages** and select **Pages**.
  
  3.  **Upload assets**: Select the name of your website and upload the
      folder where you saved your index.html file.
  
  4.  **Wait for a few minutes** -- Your website will be live!
  
  ### Customization
  
  You can **edit the code** as you want to enhance or modify the project.
  
  ## Prompt
  
  Objective:
  
  Create a single-file web application using HTML, CSS, and JavaScript
  that allows users to generate images based on a given text prompt. The
  application should be fully functional when opened without any external
  dependencies.
  
 Functionality:
  
  -   User Input: Provide an input field where users can enter a text
      prompt.
  
  -   Aspect Ratio Selection: Implement radio buttons to select an
      aspect ratio:
  
      -   Square (800x800)
  
      -   Portrait (800x1200)
  
      -   Landscape (1200x800)
  
  -   Generate Button: When clicked, it should:
  
      1.  Encode the user's prompt into a URL-friendly format (e.g.,
          spaces converted to %20).
  
      2.  Use the selected aspect ratio to set the width and height.
  
      3.  Construct the image URL following this format:
          https://image.pollinations.ai/prompt/The%20prompt%20here?width=800&height=800&nologo=true
  
      4.  Display the generated image directly on the page.
  
      5.  Add a **Download Image** button once the image loads, allowing
          users to save it.
  
  Design & Layout:
  
  -   Modern Dashboard-Style UI:
  
      -   A visually appealing, clean, and intuitive design.
  
      -   Responsive layout to ensure a seamless experience across
          different screen sizes.
  
      -   Minimalistic styling using CSS for an elegant look.
  
  -   User Interface Enhancements:
  
      -   Clearly labeled controls.
  
      -   Proper spacing and alignment for a structured look.
  
      -   Hover effects and subtle transitions for a smooth UX.
  
  Additional Features:
  
  1.  Download Button: After the image loads, display a "Download
      Image" button.
  
  2.  Dashboard Layout: Style the layout to resemble a modern
      dashboard for a professional look.
  
  The entire implementation should be self-contained in a **single
  index.html file**, making it functional as soon as opened without any
  additional files or dependencies.`
    },
    {
        id: 2,
        title: "Create a Neural Network from Scratch",
        description: "Build and train a simple neural network using only vanilla JavaScript",
        url: "https://www.youtube.com/watch?v=example2",
        author: "Dev Matrix",
        authorUsername: "devmatrix",
        content: `# Coming soon...`
    },
    {
        id: 3,
        title: "Build a Chat App with React and Firebase",
        description: "Learn how to create a real-time chat application with user authentication",
        url: "https://www.youtube.com/watch?v=example3",
        author: "Code Aura",
        authorUsername: "codeaura",
        content: `# Coming soon...`
    }
];

export default videoData;