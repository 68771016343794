import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './VideoCard.module.css';

const VideoCard = ({ video }) => {
    const [thumbnail, setThumbnail] = useState('');
    const [title, setTitle] = useState(video.title || '');
    const [channelUrl, setChannelUrl] = useState('');

    useEffect(() => {
        // Extract video ID from YouTube URL
        const getYouTubeID = (url) => {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);
            return (match && match[2].length === 11) ? match[2] : null;
        };

        const videoId = getYouTubeID(video.url);

        if (videoId) {
            // Set the high quality thumbnail URL
            setThumbnail(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);

            // If no title provided, you could fetch it from YouTube API here
            // but for now we'll use the provided title or video ID as fallback
            if (!video.title) {
                setTitle(`YouTube Video: ${videoId}`);
            }

            // If we have a channel ID, construct the channel URL
            if (video.authorChannelId) {
                setChannelUrl(`https://www.youtube.com/channel/${video.authorChannelId}`);
            } else if (video.authorUsername) {
                setChannelUrl(`https://www.youtube.com/@${video.authorUsername}`);
            }
        }
    }, [video.url, video.title, video.authorChannelId, video.authorUsername]);

    // Function to handle author name click
    const handleAuthorClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (channelUrl) {
            window.open(channelUrl, '_blank');
        }
    };

    return (
        <Link to={`/video/${video.id}`} className={styles.cardWrapper}>
            <div className={styles.card}>
                <div className={styles.thumbnailContainer}>
                    <img
                        src={thumbnail}
                        alt={title}
                        className={styles.thumbnail}
                    />
                    <div className={styles.overlay}>
                        <span className={styles.viewDetails}>View Details</span>
                    </div>
                </div>
                <div className={styles.info}>
                    {video.author && (
                        <div
                            className={styles.author}
                            onClick={handleAuthorClick}
                        >
                            {video.author}
                        </div>
                    )}
                    <h3 className={styles.title}>{title}</h3>

                    {video.description && (
                        <p className={styles.description}>{video.description}</p>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default VideoCard;